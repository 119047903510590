<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>竞买竞卖订单</span></div>

			<el-tabs v-model="orderStatus" @tab-click="handleClick">
				<el-tab-pane label="我挂售的" name="all"></el-tab-pane>
				<el-tab-pane label="我买的" name="waitpay"></el-tab-pane> 
				 
			</el-tabs>

			<div v-loading="loading">
				<nav>
					<li style="width:40%">订单信息</li>
					<li style="width:10%">售价</li>
					<li style="width:10%">数量</li>
					<li style="width:20%">挂售日期</li>
					<li style="width:10%">订单状态</li>
					<li style="width:10%">出售信息</li>
				</nav>

				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head"> 
						    
							<span class="order-no">订单号：{{ orderItem.order_no }}</span>
							<!-- <router-link :to="'/shop-' + orderItem.site_id" target="_blank">{{ orderItem.site_name }}</router-link> -->
							 <span>下单时间：{{ $util.timeStampTurnTime(orderItem.orderinfo.create_time) }}</span>
						</div>
						<ul  >
							<li style="width:40%">
								<div class="img-wrap" @click="$router.pushToTab('/presku-' + orderItem.goods_id)">
									<img :src="$img(orderItem.goods_image, { size: 'mid' })"  />
								</div>
								<div class="info-wrap" @click="$router.pushToTab('/presku-' + orderItem.goods_id)">
									<h5>{{ orderItem.goods_name }}</h5> 
								</div>
								 
							</li>
							<li style="width:10%">
								<span>￥{{ orderItem.price }}</span>
							</li>
							<li style="width:10%">
								<span>{{ orderItem.salesum }}</span>
							</li>
							<li style="width:20%">
								<span>{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
							</li>
							<li style="width:10%">
								<span v-if="orderItem.status==1">挂售中
								<button @click="cancelsale(orderItem.order_sale_id)">取消挂售</button>
								</span>
								
								<span v-if="orderItem.status==2">已出售</span>
							</li>
							<li style="width:10%">
								<span v-if="orderItem.getstatus=='all'&&orderItem.buy_member>0">购买人：{{ orderItem.buy_member_name }}</span>
								<span v-if="orderItem.getstatus=='waitpay'">出售人：{{ orderItem.memberinfo.username }}
								<button @click="gotosale(orderItem.order_sale_id)">去远期挂售</button>
								</span>
							</li>
							 
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关订单</div>
			</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage"
				 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
				 hide-on-single-page></el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		apiSaleOrderList
	} from '@/api/order/order';
	
	import { cancelsale } from '@/api/order/refund';
	import orderMethod from '@/utils/orderMethod';
	import CountDown from "vue2-countdown"
	export default {
		name: 'order_list',
		components: {
			CountDown
		},
		data: () => {
			return {
				orderStatus: 'all',
				loading: true,
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				yes: true
			};
		},
		mixins: [orderMethod],
		created() {
			this.orderStatus = this.$route.query.status || 'all';
			this.getOrderList();
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			countDownS_cb() {},
			countDownE_cb() {},
			handleClick(tab, event) {
				this.currentPage = 1;
				this.orderStatus = tab.name;
				this.refresh();
			},
			getOrderList() {
				console.log(",,,,,,,," );
				apiSaleOrderList({
						page: this.currentPage,
						page_size: this.pageSize,
						order_status: this.orderStatus,
						orderbuytype: 2
					})
					.then(res => {
						 //console.log(",,,,,,,,",JSON.stringify(res));
						let list = [];
						 
						if (res.code == 0 && res.data) { 
							list = res.data;
							this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},
			operation(action, orderData) {
				let index = this.status;
				switch (action) {
					case 'orderPay': // 支付
						this.orderPay(orderData);
						break;
					case 'orderClose': //关闭
						this.orderClose(orderData.order_id, () => {
							this.refresh();
						});
						break;
					case 'memberTakeDelivery': //收货
						this.orderDelivery(orderData.order_id, () => {
							this.refresh();
						});
						break;
					case 'trace': //查看物流
						this.$router.push({
							path: '/member/logistics',
							query: {
								order_id: orderData.order_id
							}
						});
						break;
					case 'memberOrderEvaluation': //评价
						this.$router.pushToTab({
							path: '/evaluate',
							query: {
								order_id: orderData.order_id
							}
						});
						break;
				}
			},
			cancelsale(order_sale_id){
				this.$confirm("确定要取消吗？", "提示信息", {
					confirmButtonText: "是",
					cancelButtonText: "否",
					type: "warning"
				}).then(() => {
					let submit_data = { 
						order_sale_id: order_sale_id 
					};
					 cancelsale(submit_data)
					 	.then(res => {
					 		const { code, message, data } = res;
					 		if (code >= 0) {
					 			this.$message({
					 				message: '恭喜，取消成功！',
					 				type: 'success',
					 				duration: 1000,
					 				onClose: () => {
					 					this.$router.go(0);
					 				}
					 			});
					 		} else {
					 			this.isSub = false;
					 			this.$message({ message: message, type: 'warning' });
					 		}
					 	})
					 	.catch(err => {
					 		this.$message.error({
					 			message: err.message,
					 			duration: 2000,
					 			onClose: () => {
					 				this.$router.push({ path: '/member/pre_order_list' });
					 			}
					 		});
					 	});
				})
			},
			gotosale(order_sale_id){
				this.$router.push({
					path: '/member/pre_order_list',
					query: {
						order_id: order_sale_id
					}
				});
			},
			orderDetail(data) {
				switch (parseInt(data.order_type)) {
					case 2:
						// 自提订单
						this.$router.push({
							path: '/member/order_detail_pickup',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 3:
						// 本地配送订单
						this.$router.push({
							path: '/member/order_detail_local_delivery',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 4:
						// 虚拟订单
						this.$router.push({
							path: '/member/order_detail_virtual',
							query: {
								order_id: data.order_id
							}
						});
						break;
					default:
						this.$router.push({
							path: '/member/order_detail',
							query: {
								order_id: data.order_id
							}
						});
						break;
				}
			},
			imageError(orderIndex, goodsIndex) {
				this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.order-list {
		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			margin-bottom: 10px;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 45%;
				}

				&:nth-child(2) {
					width: 10%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 10%;
				}

				&:nth-child(5) {
					width: 15%;
				}

				&:nth-child(6) {
					width: 10%;
				}
			}
		}

		.list {
			.item {
				margin-bottom: 20px;
				border: 1px solid #eeeeee;
				border-top: 0;

				.head {
					padding: 8px 10px;
					background: #f7f7f7;
					font-size: 12px;

					.create-time {
						margin-right: 10px;
					}

					border-bottom: 1px solid #eeeeee;

					a {
						margin: 0 10px 0 20px;
					}

					.order-type {
						margin-left: 30px;
					}
				}
				.order-time {
					display: flex;
					align-items: center;
					font-size: 10px;
				}
			}

			ul {
				background-color: #fff;
				padding: 10px;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 45%;
						line-height: inherit;

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 10%;
					}

					&:nth-child(3) {
						width: 10%;
					}

					&:nth-child(4) {
						width: 10%;
					}

					&:nth-child(5) {
						width: 15%;
						line-height: 30px;

						a {
							display: block;
						}
					}

					&:nth-child(6) {
						width: 10%;
						line-height: initial;

						button {
							margin-left: 0;
							margin-bottom: 10px;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.empty-wrap {
			text-align: center;
			padding: 10px;
		}
	}
</style>
